import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {

        TestUrlNew: 'https://www.tdetodreameverything.cn:8080/api/', //'http://192.168.8.235:7081/api/', //'https://www.tdetodreameverything.cn:8080/api/',//主程序接口表
        FileStorageServerUrl: "https://www.tdetodreameverything.cn:8082/api/",//文件存储接口配置表
        GetFileUrl: "https://www.tdetodreameverything.cn:8082/",//文件存储 获取文件的接口表
        token: null, //请求token
        getUserinfo: null, //用户信息
        IsLogin: false
    },
    getters: {},
    mutations: {
        changeGetUserinfo(state, n) {
            state.getUserinfo = n;
        },
        changeIsLogin(state, n) {
            state.IsLogin = true;
        }
    },
    actions: {},
    modules: {}
})