import Vue from 'vue';
import App from './App.vue';
import router from './router';

//路由
import store from './store';
//vueX
import cookie from 'js-cookie';
//cookie存储
import {
    Base64
} from 'js-base64';
//base64转换
import 'font-awesome/css/font-awesome.css';
//字体图标
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
//ElementUI components
import "./plugs/colorui/main.css";
import "./plugs/colorui/icon.css";
import "./plugs/colorui/animation.css";
//colorui 
import XLSX from 'xlsx';
//excel 解析与下载
import untils from "./untils/untils";
//个人封装工具集
import './common/main.css';
//系统全局样式
import * as echarts from 'echarts';
//图表插件
import dataV from '@jiaminghi/data-view';
//大屏动态效果插件
import NProgress from 'nprogress' // 进度条插件 可以放在ajax和页面路由中
import 'nprogress/nprogress.css' //这个样式必须引入
import carPlateKeyboard from 'car-plate-keyboard' //虚拟车牌
import 'car-plate-keyboard/lib/carPlateKeyboard.css' //虚拟车牌
import VForm from 'vform-builds'  //引入VForm库
import 'vform-builds/dist/VFormDesigner.css'  //引入VForm样式


Vue.use(VForm)  //全局注册VForm(同时注册了v-form-designe、v-form-render等组件)
Vue.use(carPlateKeyboard)
Vue.prototype.$cookie = cookie;
Vue.prototype.$base64 = Base64;
Vue.use(ElementUI);
Vue.use(dataV);
Vue.prototype.$xlsx = XLSX;
Vue.prototype.$untils = untils;
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;



//路由守卫，此权限已关闭
// router.beforeEach((to,from,next)=>{
//   NProgress.start();
//   if(to.meta.requireAuth){   
//       // console.log(userLimisit);
//       let Common=["Login","Index","Home","Error"]
//       if(Common.includes(to.meta.requireAuth)){
//         next();
//       }else{
//         let userLimisit=(Vue.prototype.$base64.decode(Vue.prototype.$cookie.get('Ranges'))).split(',');
//         if(userLimisit.includes(to.meta.requireAuth)){

//           next()

//         }else{
//           alert('请联系管理员添加权限');
//         }
//       }







let isLogin = false;
if (cookie.get('token')) {
    isLogin = true;
}

if (isLogin) {
    // console.log("main.js加载", );
    // 从 localStorage 中加载子路由信息
    let localroutes = JSON.parse(localStorage.getItem('rootMuens'));

    function addRoute(parentRouter, routes) {
        for (let i = 0; i < routes.length; i++) {
            let obj = {
                path: routes[i].Path,
                // component:(resolve) => require([`@/views/${routes[i].Component}`],resolve),
                component: () => import(`@/views/${routes[i].Component}`),
                name: routes[i].Name,
                label: routes[i].Label,
                meta: {
                    title: routes[i].MetaTitle,
                    requireAuth: routes[i].MetaRequireAuth
                }
            };
            router.addRoute(parentRouter, obj);
            if (routes[i].Children.length > 0) {
                addRoute(routes[i].Name, routes[i].Children);
            }
        }


    };

    router.onReady(() => {
        addRoute('Index', localroutes);
        router.addRoute({
            path: '/:catchAll(.*)',
            name: 'Error',
            label: '错误页面',
            meta: {
                title: '错误页面',
                requireAuth: 'Error' //为这个页面通用权限
            },
            component: () => import('@/views/Home/Error/index.vue')
        });
    });



}

// 简单配置进度条插件
NProgress.inc(0.2);
NProgress.configure({
    easing: 'ease',
    speed: 500,
    showSpinner: false,
    parent: '#tagsContainer'
});
//ease：调整动画设置，ease可传递CSS3缓冲动画字符串（如ease、linear、ease-in、ease-out、ease-in-out、cubic-bezier）。speed为动画速度（单位ms）。
// 导航守卫 添加进度条开始 和结束标识
router.beforeEach((to, from, next) => { //路由开始时做什么事情
    NProgress.start();
    next();
});
router.afterEach(() => { //路由结束时做什么事情
    NProgress.done()
})



new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')