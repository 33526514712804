import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes1 = [
  {
    path: '/',
    name: 'Login',
    label:'登录页',
    component: () => import('../views/Login/login.vue'),
    meta:{
      title:'登录页',
      requireAuth:'Login'//为这个页面通用权限
    }
  },
  {
    path: '/Index',
    name: 'Index',
    label:'主页',
    //redirect:'/index/Home',  //重定向
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Home/index.vue'),
    meta:{
      title:'主页',
      requireAuth:'Index'//为这个页面通用权限
    },
    children:[
      {
        path:'Home',
        component:() => import('@/views/Home/Home/index.vue'),
        name:'Home',
        label:'系统首页',
        meta:{
          title:'系统首页',
          requireAuth:'Home'
        }
      },
      {
        path:'Organization',
        component:() => import('@/views/Home/Organization/index.vue'),
        name:'Organization',
        label:'组织机构',
        meta:{
          title:'组织机构',
          requireAuth:'Organization'
        },
        children:[
          {
            path:'Organmanage',
            component:() => import('@/views/Home/Organization/Organmanage/index.vue'),
            name:'Organmanage',
            label:'机构管理',
            meta:{
              title:'机构管理',
              requireAuth:'Organmanage'
            }
          },
          {
            path:'NewEmployeePage',
            component:() => import('@/views/Home/Organization/NewEmployeePage/index.vue'),
            name:'NewEmployeePage',
            label:'新员工入职审核',
            meta:{
              title:'新员工入职审核',
              requireAuth:'NewEmployeePage'
            }
          },
          {
            path:'NewEmployee/:id',
            component:() => import('@/views/Home/Organization/NewEmployee/index.vue'),
            name:'NewEmployee',
            label:'新员工审批', 
            meta:{
              title:'新员工审批',
              requireAuth:'NewEmployee'
            }
          },
          {
            path:'userManagement',
            component:() => import('@/views/Home/Organization/userManagement'),
            name:'userManagement',
            label:'账号及权限管理',
            meta:{
              title:'账号及权限管理',
              requireAuth:'userManagement'
            }
          },
          {
            path:'uploadFaceInfo', 
            component:() => import('@/views/Home/Organization/uploadFaceInfo'),
            name:'uploadFaceInfo',
            label:'同步人脸采集设备信息',
            meta:{
              title:'同步人脸采集设备信息',
              requireAuth:'uploadFaceInfo'
            }
          },
        ]
      },
      {
        path:'Maintenance',
        component:() => import('@/views/Home/Maintenance/index.vue'),
        name:'Maintenance',
        label:'维护模块',
        meta:{
          title:'维护模块',
          requireAuth:'Maintenance'
        },
        children:[
          {        
          path:'Canteen',
          component:() => import('@/views/Home/Maintenance/Canteen/index.vue'),
          name:'Canteen',
          label:'食堂模块',
          meta:{
            title:'食堂模块',
            requireAuth:'Canteen'
          },
          children:[
            {
              path:'Notch',
              component:() => import('@/views/Home/Maintenance/Canteen/Notch/index.vue'),
              name:'Notch',
              label:'餐票档口设置',
              meta:{
                title:'餐票档口设置',
                requireAuth:'Notch'
              }
            },
          ]
         },
         {
          path:'Weixing',
          component:() => import('@/views/Home/Maintenance/Weixing/index.vue'),
          name:'Weixing',
          label:'微信小程序维护',
          meta:{
            title:'微信小程序维护',
            requireAuth:'Weixing'
          },
          children:[
            {
              path:'WxIndexComponent',
              component:() => import('@/views/Home/Maintenance/Weixing/WxIndexComponent'),
              name:'WxIndexComponent',
              label:'微信小程序导航栏维护',
              meta:{
                title:'微信小程序导航栏维护',
                requireAuth:'WxIndexComponent'
              },
            }
          ]
        },
        {
          path:'OrganizationMaintenance',
          component:() => import('@/views/Home/Maintenance/OrganizationMaintenance/index.vue'),
          name:'OrganizationMaintenance',
          label:'组织机构',
          meta:{
            title:'组织机构',
            requireAuth:'OrganizationMaintenance'
          },
          children:[
            {
              path:'LockBranch', 
              component:() => import('@/views/Home/Maintenance/OrganizationMaintenance/LockBranch/index.vue'),
              name:'LockBranch',
              label:'锁定机构维护',
              meta:{
                title:'锁定机构维护',
                requireAuth:'LockBranch'
              }
            },
            {
              path:'NewEmployeeInduction',
              component:() => import('@/views/Home/Maintenance/OrganizationMaintenance/NewEmployeeInduction'),
              name:'NewEmployeeInduction',
              label:'新员工入职维护',
              meta:{
                title:'新员工入职维护',
                requireAuth:'NewEmployeeInduction'
              }
            },
            {
              path:'laborService',
              component:() => import('@/views/Home/Maintenance/OrganizationMaintenance/laborService'),
              name:'laborService',
              label:'劳务公司维护',
              meta:{
                title:'劳务公司维护',
                requireAuth:'laborService'
              }
            },
            {
              path:'JobMaintenance', 
              component:() => import('@/views/Home/Maintenance/OrganizationMaintenance/JobMaintenance'),
              name:'JobMaintenance',
              label:'岗位维护',
              meta:{
                title:'岗位维护',
                requireAuth:'JobMaintenance'
              }
            },      
          ]
        },
        {
            path:'Equipment',
            component:() => import('@/views/Home/Maintenance/Equipment'),
            name:'Equipment',
            label:'设备维护',
            meta:{
              title:'设备维护',
              requireAuth:'Equipment'
            },
            children:[
              {
                path:'EquipmentMaintenance',
                component:() => import('@/views/Home/Maintenance/Equipment/EquipmentMaintenance'),
                name:'EquipmentMaintenance',
                label:'设备维护',
                meta:{
                  title:'设备维护',
                  requireAuth:'EquipmentMaintenance'
                }
              },
              {
                path:'InterfaceMaintenance',
                component:() => import('@/views/Home/Maintenance/Equipment/InterfaceMaintenance'),
                name:'InterfaceMaintenance',
                label:'硬件接口维护',
                meta:{
                  title:'硬件接口维护',
                  requireAuth:'InterfaceMaintenance'
                }
              },
            ]
        },
        {
          path:'System',
          component:() => import('@/views/Home/Maintenance/System'),
          name:'System',
          label:'系统维护',
          meta:{
            title:'系统维护',
            requireAuth:'System'
          },
          children:[
            {
              path:'DictionaryMaintenance', 
              component:() => import('@/views/Home/Maintenance/System/DictionaryMaintenance'),
              name:'DictionaryMaintenance',
              label:'系统字典维护',
              meta:{
                title:'系统字典维护',
                requireAuth:'DictionaryMaintenance'
              }
            },
            {
              path:'MenuMaintenance', 
              component:() => import('@/views/Home/Maintenance/System/MenuMaintenance'),
              name:'MenuMaintenance',
              label:'菜单维护',
              meta:{
                title:'菜单维护',
                requireAuth:'MenuMaintenance'
              }
            },
            {
              path:'Authority', 
              component:() => import('@/views/Home/Maintenance/System/Authority'),
              name:'Authority',
              label:'角色维护',
              meta:{
                title:'角色维护',
                requireAuth:'Authority'
              }
            },
          ]
        }
        ]
      },
      {
        path:'Approve',
        component:() => import('@/views/Home/Approve/index.vue'),
        name:'Approve',
        label:'审批模块',
        meta:{
          title:'审批模块',
          requireAuth:'Approve'
        },
        children:[
          {
            path:'Accommodation',
            component:() => import('@/views/Home/Approve/Accommodation/index.vue'),
            name:'Accommodation',
            label:'宿舍模块',
            meta:{
              title:'宿舍模块',
              requireAuth:'Accommodation'
            },
            children:[
                {            
                path:'RepairApplication',
                component:() => import('@/views/Home/Approve/Accommodation/RepairApplication/index.vue'),
                name:'RepairApplication',
                label:'报修申请',
                meta:{
                  title:'报修申请',
                  requireAuth:'RepairApplication'
                }
                },
                {            
                  path:'ResidencyApplication',
                  component:() => import('@/views/Home/Approve/Accommodation/ResidencyApplication/index.vue'),
                  name:'ResidencyApplication',
                  label:'退宿申请',
                  meta:{
                    title:'退宿申请',
                    requireAuth:'ResidencyApplication'
                  }
                  },
            ]
          }
        ]
      },
      {
        path:'ReportForms',
        component:() => import('@/views/Home/ReportForms'),
        name:'ReportForms',
        label:'报表',
        meta:{
          title:'报表',
          requireAuth:'ReportForms'
        },
        children:[
          {
            path:'UserScreen', 
            component:() => import('@/views/Home/ReportForms/UserScreen'),
            name:'UserScreen',
            label:'全员花名册报表',
            meta:{
              title:'全员花名册报表',
              requireAuth:'UserScreen'
            }
          },
          {
            path:'AssignmentTable', 
            component:() => import('@/views/Home/ReportForms/AssignmentTable'),
            name:'AssignmentTable',
            label:'员工分配表',
            meta:{
              title:'员工分配表',
              requireAuth:'AssignmentTable'
            }
          },
          {
            path:'MealTicket',
            component:() => import('@/views/Home/ReportForms/MealTicket'),
            name:'MealTicket',
            label:'餐票查询',
            meta:{
              title:'餐票查询',
              requireAuth:'MealTicket'
            }
          },
        ]
      },
      {
        path:'Tools',
        component:() => import('@/views/Home/Tools'),
        name:'Tools',
        label:'工具',
        meta:{
          title:'工具',
          requireAuth:'Tools'
        },
        children:[
          {
            path:'WebSocketTest', 
            component:() => import('@/views/Home/Tools/WebSocketTest'),
            name:'WebSocketTest',
            label:'WebSockets测试',
            meta:{
              title:'WebSockets测试',
              requireAuth:'WebSocketTest'
            }
          },
          {
            path:'formCreate', 
            component:() => import('@/views/Home/Tools/formCreate'),
            name:'formCreate',
            label:'表单生成器',
            meta:{
              title:'表单生成器',
              requireAuth:'formCreate'
            }
          }
        ]
      },
      {
        path:'Users',
        component:() => import('@/views/Home/Users'),
        name:'Users',
        label:'用户模块',
        meta:{
          title:'用户模块',
          requireAuth:'Users'
        },
        children:[
          {
            path:'PersonalCenter',
            component:() => import('@/views/Home/Users/PersonalCenter'),
            name:'PersonalCenter',
            label:'个人中心',
            meta:{
              title:'个人中心',
              requireAuth:'PersonalCenter'
            },
          }
        ]
      },





    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'Error',
    label:'错误页面',
    meta: {
      title:'错误页面',
      requireAuth:'Error'//为这个页面通用权限
    },
    component: () => import('@/views/Home/Error/index.vue')
  },
]

const routes=[
  {
    path: '/',
    name: 'Login',
    label:'登录页',
    component: () => import('../views/Login/login.vue'),
    meta:{
      title:'登录页',
      requireAuth:'Login'//为这个页面通用权限
    }
  },
  {
    path: '/Index',
    name: 'Index',
    label:'主页',
    //redirect:'/index/Home',  //重定向
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Home/index.vue'),
    meta:{
      title:'主页',
      requireAuth:'Index'//为这个页面通用权限
    }
    },
    {
        path: '/EmploymentApplication',
        name: 'EmploymentApplication',
        label:'入职申请',
        component: () => import('../views/EmploymentApplication/index.vue'),
        meta:{
          title:'入职申请',
          requireAuth:'EmploymentApplication'//为这个页面通用权限
        }
      }
]
const router = new VueRouter({
  routes
})

export function resetRouter() {
  const newRouter = new VueRouter({
    routes
  })
  router.matcher = newRouter.matcher // 重置路由匹配器
}
export default router
