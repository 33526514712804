//引用外部插件
import axios from 'axios'
import store from '../store/index'
import md5 from 'js-md5'
import cookie from 'js-cookie'
import {
    Base64
} from 'js-base64'
import router from '../router'
import XLSX from 'xlsx'
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//配置插件
let base64 = Base64;
//配置axios
var currentHost = window.location.hostname;
var currentPort = window.location.port;
// axios.defaults.baseURL = store.state.baseurl;//腾讯云服务器
axios.defaults.baseURL = store.state.TestUrlNew; //测试环境接口地址
var FileStorageServerUrl = store.state.FileStorageServerUrl; //文件存储服务器地址
//配置部署环境
//axios.defaults.baseURL=store.state.TestUrlNew.replace(/^http:\/\/[^/]+/, 'http://' + currentHost);




//异步请求允许携带资源凭证,这个也是渣渣！添加了反而会报跨越错误！
//axios.defaults.withCredentials=true;
//设置请求头
// axios.defaults.headers["Content-Type"] ="application/x-www-form-urlencoded";
axios.defaults.headers["Content-Type"] = "application/json";
// axios.defaults.headers["Content-Type"] ="multipart/form-data";


//对请求数据进行处理 格式 multipart/form-data
// axios.defaults.transformRequest = function (data) {
// //console.log(JSON.stringify(data));
// if (!data) return data;
// let str = "";

// for (let key in data) {
//     if (!data.hasOwnProperty(key)) break;
//     str += "&" + key + "=" + data[key];
// }
// return str.substring(1);
// };

//对请求数据进行处理 格式 json
// axios.defaults.transformRequest=function(data){
// if(!data) return data;
// data=JSON.stringify(data);
// return data;
// }

// 配置请求拦截器添加token token需要从cookie中获取
axios.interceptors.request.use(config => {

    if (cookie.get('token')) {
        let token = base64.decode(cookie.get('token'));
        if (token) {
            config.headers['authorization'] = `Bearer ${token}`
        }
        return config;
    } else {
        return config;
    }

});
//配置axios返回拦截器
axios.interceptors.response.use(
    response => {
        // console.log('响应拦截器成功的回调执行了',response);
        let code = response.data.code ? response.data.code : false;
        if (code == 401) {
            //清除所有cookie
            cookie.remove("userId");
            cookie.remove("token");
            cookie.remove("ReserveToken");
            cookie.remove("nanme");
            localStorage.removeItem("rootMuens");
            canOnlyFireOnce();
            router.push({
                path: "/"
            });
        } else {
            return response
        }

    },

    // 作用： 如果 请求失败 统一放在响应拦截器这里处理 
    error => { // 
        if (error.response.status === 403) { // 对响应错误做点什么
            units.Warning('您没有权限进行此操作,请联系管理人员给您添加权限！');
        } else {

            return Promise.reject(error);
            //此处必须按照官网写，否则catch拦截不到错误信息
        }

    }
);

function once(fn, context) { //仅执行一次函数
    var result;

    return function () {
        if (fn) {
            result = fn.apply(context || this, arguments);
            fn = null;
        }

        return result;
    };
}

var canOnlyFireOnce = once(function () {
    alert("登录令牌到期,请重新登录！");
});




//定义全局变量
const units = {};
units.Success = msg => {
    ElementUI.Notification({
        title: '成功',
        message: msg,
        type: 'success'
    });
}
units.Warning = msg => {
    ElementUI.Notification({
        title: '警告',
        message: msg,
        type: 'warning'
    });
}
units.formatTimeT = date => {
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var day = date.getDate()
    var hours = date.getHours()
    var minutes = date.getMinutes()
    var seconds = date.getSeconds()
    var milliseconds = date.getMilliseconds()

    // 将月、日、小时、分钟和秒按照需要的格式进行补零
    month = (month < 10 ? '0' : '') + month
    day = (day < 10 ? '0' : '') + day
    hours = (hours < 10 ? '0' : '') + hours
    minutes = (minutes < 10 ? '0' : '') + minutes
    seconds = (seconds < 10 ? '0' : '') + seconds
    milliseconds = (milliseconds < 10 ? '00' : (milliseconds < 100 ? '0' : '')) + milliseconds

    // 拼接时间字符串，并返回结果
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
}
units.formatTime = date => { //格式化时间格式
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()

    return `${[year, month, day].map(formatNumber).join('/')} ${[hour, minute, second].map(formatNumber).join(':')}`
}

const formatNumber = n => {
    n = n.toString()
    return n[1] ? n : `0${n}`
}
units.formatDate = date => {
    if (!date) return null;
    const dateObj = new Date(date);
    const y = dateObj.getFullYear();
    const m = String(dateObj.getMonth() + 1).padStart(2, '0');
    const d = String(dateObj.getDate()).padStart(2, '0');
    return `${y}-${m}-${d}`;
}
units.formatSQLDate = date => {
    let regx = /[\-0-9]+/g;
    return date.match(regx)[0];
}
units.datemonth = (date1, date2) => {
    try {
        // 将日期字符串转换为日期对象
        date1 = new Date(date1);
        date2 = new Date(date2);
        // 计算时间差
        var diffTime = Math.abs(date1.getTime() - date2.getTime());
        // 计算月份，精确到小数点后一位
        var months = (diffTime / (1000 * 60 * 60 * 24 * 30)).toFixed(1);
        return months;
    } catch (err) {
        return null;
    }

}
//时间格式转换为 时间戳
units.parseYearMonthStringTotimestamp = (yearMonthString) => {
    var parts = yearMonthString.split("-");
    var year = parseInt(parts[0]);
    var month = parseInt(parts[1]);
    // 使用 UTC 日期时间
    //var date = new Date(year, month - 1, 1);
    var date = new Date(Date.UTC(year, month - 1, 1));
    // 获取时间戳（毫秒）
    var timestamp = date.getTime();
    return timestamp;
}
//时间戳转换为 时间字符串
units.timestampToYearMonthString = (timestamp) => {
    var date = new Date(timestamp);
    var year = date.getFullYear();
    var month = date.getMonth() + 1; // 月份从 0 开始，所以需要加 1

    // 格式化成 "YYYY-MM" 格式
    var formattedDate = year + "-" + (month < 10 ? "0" : "") + month;

    return formattedDate;
}
units.postaxiosCommon = (url, parames) => { //封装post,用于需要进行sql循环处理data类型
    if (cookie.get('userId')) {
        let userId = base64.decode(cookie.get('userId'));
        parames.jsonParams.userId = userId;
        parames.jsonParams = JSON.stringify(parames.jsonParams); //保障传给数据库的还是json
        let axiospost = axios.post(url, JSON.stringify(parames));
        return axiospost;
    } else {
        let axiospost = axios.post(url, JSON.stringify(parames));
        return axiospost;
    }
}

units.postaxios = (url, parames) => { //封装post,存post请求
    if (cookie.get('userId')) {
        let userId = base64.decode(cookie.get('userId'));
        parames.userId = userId;
        let axiospost = axios.post(url, JSON.stringify(parames));
        return axiospost;
    } else {
        let axiospost = axios.post(url, JSON.stringify(parames));
        return axiospost;
    }
}
units.Justpostaxios = (url, parames) => { //封装post,存post请求
    let axiospost = axios.post(url, JSON.stringify(parames));
    return axiospost;
}
units.getaxiosCommon = (url, parames) => { //封装get
    if (cookie.get('userId')) {
        let userId = base64.decode(cookie.get('userId'));
        parames.userId = userId;
        let axiosget = axios.get(url, {
            params: parames
        })
        return axiosget;
    } else {
        let axiosget = axios.get(url, {
            params: parames
        })
        return axiosget;
    }

}
units.GetUserId = () => { //获取工号
    let userId = base64.decode(cookie.get('userId'));
    return userId;
}
//使用EF框架后，需要重新定义请求
units.HttpGet = (url, parames) => {
    let HttpGet_axios = axios.get(url, {
        params: parames
    });
    return HttpGet_axios;
}

units.HttpPost = (url, parames) => {
    let HttpPost_axios = axios.post(url, parames);
    return HttpPost_axios;
}
units.HttpPostByHeaders = (url, parames, headers) => {
    let HttpPost_axios = axios.post(url, parames, {
        headers
    });
    return HttpPost_axios;
}

units.HttpDelete = (url, parames) => {
    let HttpDelete_axios = axios.delete(url, {
        params: parames
    });
    return HttpDelete_axios;
}
//使用EF框架后，需要重新定义请求

//创建新的axios请求实例进行请求
units.CreateHttpGet = (url, parames, timeout = 50000) => {
    //创建新的axios实例，用于对不同服务器地址的请求
    let token = base64.decode(cookie.get('token'));
    let instance = axios.create({
        baseURL: url,
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        timeout: timeout,
    });
    let HttpGet_axios = instance.get('', {
        params: parames
    });
    return HttpGet_axios;
}
units.CreateHttpPost = (url, parames, timeout = 5000000) => {
    //创建新的axios实例，用于对不同服务器地址的请求
    let token = base64.decode(cookie.get('token'));
    let instance = axios.create({
        baseURL: url,
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        },
        timeout: timeout,
    });
    let HttpPost_axios = instance.post('', parames);
    return HttpPost_axios;
}

units.CreateUploadHttpPost = (url, formData, timeout = 5000000) => {
    //创建新的axios实例，用于实现文件上传
    let instance = axios.create({
        baseURL: FileStorageServerUrl + url,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        timeout: timeout,
        maxContentLength: Infinity, // 增加最大内容长度
        maxBodyLength: Infinity, // 增加最大请求体长度
    });
    let HttpPost_axios = instance.post('', formData);
    return HttpPost_axios;
}
units.uploadFile = (url, formData) => {

    var xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);

    xhr.upload.onprogress = function (e) {
        if (e.lengthComputable) {
            var percentComplete = (e.loaded / e.total) * 100;
            console.log('上传进度: ' + percentComplete + '%');
        }
    };

    xhr.onload = function () {
        if (xhr.status === 200) {
            var response = xhr.responseText;
            console.log('上传成功');
            console.log('服务器返回的数据: ', response);

            // 在这里可以处理服务器返回的数据
        } else {
            console.error('上传失败: ' + xhr.status);
        }
    };

    xhr.onerror = function () {
        console.error('网络错误');
    };
    xhr.timeout = 0; // 0 表示没有超时限制
    xhr.send(formData);
}

units.MD5 = (data) => { //对数据进行MD5加密
    data = data.toString(); //md5 参数必须为字符串
    return md5(data);
}
units.enBs64 = data => { //加密
    return base64.encode(data);
}
units.deBs64 = data => { //解密
    return base64.decode(data);
}
units.GetExcel = (event) => { //解析input传过来的excel数据
    return new Promise((res, rej) => {
        if (!event.target.files[0]) return;
        let file = event.target.files[0];
        let fileName = event.target.files[0].name;
        let suffixREG = /\.([0-9a-zA-Z]+)$/;
        let suffix = suffixREG.test(fileName) ? suffixREG.exec(fileName)[1] : '';
        let suffixArry = ["xlsx", "xls", "XLS"]
        if (!suffixArry.includes(suffix)) {
            alert("上传文件目前只支持EXCEL后缀为.xls/.xlsx!");
            return;
        }

        let fileReader = new FileReader();
        fileReader.readAsBinaryString(file);
        var promise = new Promise(resolve => {
            fileReader.onload = (event) => {
                resolve(event.target.result);
            };
        });


        promise.then(result => {

            let workbook = XLSX.read(result, {
                type: "binary"
            });
            let worksheet = workbook.Sheets[workbook.SheetNames[0]];
            let dataExcel = XLSX.utils.sheet_to_json(worksheet);
            res(dataExcel)
        }).catch(err => {
            rej(err)
        })
    })

}
//根据出生年月日获取年龄 1897-04-17
// units.getAges = (str) => {
//     var r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
//     if (r == null) return false;
//     var d = new Date(r[1], r[3] - 1, r[4]);
//     if (d.getFullYear() == r[1] && (d.getMonth() + 1) == r[3] && d.getDate() == r[4]) {
//         var Y = new Date().getFullYear();
//         return ((Y - r[1]));
//     }
//     return ("输入的日期格式错误！");
// }
units.getAges = (str) => {
    var r = str.match(/^(\d{1,4})-(\d{1,2})-(\d{1,2})$/);
    if (r == null) return false;
    var birthDate = new Date(r[1], r[2] - 1, r[3]);
    if (birthDate.getFullYear() == r[1] && birthDate.getMonth() == r[2] - 1 && birthDate.getDate() == r[3]) {
        var today = new Date();
        var age = today.getFullYear() - r[1];
        var monthDiff = today.getMonth() - (r[2] - 1);

        if (today.getDate() < r[3]) {
            monthDiff -= 1;
        }

        var ageWithDecimal = age + monthDiff / 12;
        return ageWithDecimal.toFixed(1);
    }
    return "输入的日期格式错误！";
}
//根据身份证号码获取年龄
units.getAge = (idCardNum) => {
    var isValid = units.checkIdCardNum(idCardNum);
    if (!isValid) {
        console.log(idCardNum);
        console.log("身份证号码格式不正确！");
        return null;
    }

    var birthday = new Date(idCardNum.substring(6, 10), idCardNum.substring(10, 12) - 1, idCardNum.substring(12, 14));
    // var ageDifMs = Date.now() - birthday.getTime();
    // var ageDate = new Date(ageDifMs);
    // return Math.abs(ageDate.getUTCFullYear() - 1970);
    // var birthdayStr = idCardNum.substring(6, 14);
    // var birthday = new Date(birthdayStr);
    var now = new Date();

    var age = now.getFullYear() - birthday.getFullYear();
    if (now.getMonth() < birthday.getMonth() ||
        (now.getMonth() == birthday.getMonth() && now.getDate() < birthday.getDate())) {
        age--;
    }
    return age;
}

units.checkIdCardNum = (idCardNum) => {
    var regex = /^[1-9]\d{5}(19\d{2}|20(0\d|1[0-9]))((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    return regex.test(idCardNum);
}
units.downloadExcelBySheetName = (ExcelData, SheetName) => {
    // console.log("excel输出")
    let wb = XLSX.utils.book_new();
    let jsonData = JSON.parse(JSON.stringify(ExcelData));
    let jsonWs = XLSX.utils.json_to_sheet(jsonData);
    jsonWs['!cols'] = [{
            wch: 20
        },
        {
            wch: 20
        },
        {
            wch: 20
        },
        {
            wch: 20
        }
    ];
    XLSX.utils.book_append_sheet(wb, jsonWs, SheetName);
    XLSX.writeFile(wb, 'ExcelTbale.xlsx');
}
units.downloadExcel = (ExcelData) => {
    // console.log("excel输出")
    let wb = XLSX.utils.book_new();
    let jsonData = JSON.parse(JSON.stringify(ExcelData));
    let jsonWs = XLSX.utils.json_to_sheet(jsonData);
    jsonWs['!cols'] = [{
            wch: 20
        },
        {
            wch: 20
        },
        {
            wch: 20
        },
        {
            wch: 20
        }
    ];
    XLSX.utils.book_append_sheet(wb, jsonWs, '人员明细');
    XLSX.writeFile(wb, 'ExcelTbale.xlsx');
    // console.log("多页数据导入一张excel中")
    // let wb = this.$xlsx.utils.book_new();
    // let jsonData = [JSON.parse(JSON.stringify(this.tableDataByEdit)),JSON.parse(JSON.stringify(this.tableDataByCheck)),JSON.parse(JSON.stringify(this.tableDataByItem))];
    // for(let i=0;i<jsonData.length;i++){
    //     jsonData[i].forEach((item, index) => {
    //     jsonData[i][index] = {
    //         单号: item.id,
    //         申请发起人: item.applyName,
    //         出厂物资类型: item.category,
    //         审批类型: item.storey,
    //         申请日期: item.applyData,
    //         是否回厂: item.returnChange,
    //         预计回厂日期: item.returnData,
    //         审批人: item.ApprovalName,
    //         审批状态: item.oaStateName,
    //         门岗工号: item.guardUserid,
    //         门岗姓名: item.guardName,
    //         门岗放行时间: item.guardApprovalTime
    //     };
    // });

    // }
    // let jsonWs=[this.$xlsx.utils.json_to_sheet(jsonData[0]),this.$xlsx.utils.json_to_sheet(jsonData[1]),this.$xlsx.utils.json_to_sheet(jsonData[2])];
    // for(let i=0;i<jsonWs.length;i++){
    // jsonWs[i]['!cols'] = [
    //     { wch: 20 },
    //     { wch: 20 },
    //     { wch: 20 },
    //     { wch: 20 }
    // ];
    // if(i==0){
    // this.$xlsx.utils.book_append_sheet(wb, jsonWs[i], '未返厂明细');
    // }else if(i==1){
    // this.$xlsx.utils.book_append_sheet(wb, jsonWs[i], '已返厂明细');
    // }else if(i==2){
    // this.$xlsx.utils.book_append_sheet(wb, jsonWs[i], '我的单据');
    // }

    // }

    // this.$xlsx.writeFile(wb, '物资出厂明细表.xlsx');
}
units.downloadExcelAll = function () { //实现多sheet下载,arguments不能使用箭头函数
        let wb = XLSX.utils.book_new();
        let jsonWs = [];
        for (let i = 0; i < arguments.length; i++) {
            console.log(arguments[i]);
            jsonWs.push(XLSX.utils.json_to_sheet(arguments[i]));
        }


        for (let i = 0; i < jsonWs.length; i++) {
            jsonWs[i]['!cols'] = [{
                    wch: 20
                },
                {
                    wch: 20
                },
                {
                    wch: 20
                },
                {
                    wch: 20
                }
            ];

            XLSX.utils.book_append_sheet(wb, jsonWs[i], 'Excel' + i);

        }

        XLSX.writeFile(wb, 'ExcelData.xlsx');
    },
    units.downloadExcelWithHeader = (headerData, excelData, fileName, SheetName) => {
        let wb = XLSX.utils.book_new();
        let worksheet = XLSX.utils.json_to_sheet(excelData);

        // 添加表头
        for (let i = 0; i < headerData.length; i++) {
            XLSX.utils.sheet_add_aoa(worksheet, [
                [headerData[i]]
            ], {
                origin: i
            });
        }


        // 合并单元格
        let mergeRange = XLSX.utils.decode_range(worksheet['!ref']);
        mergeRange.s.r = headerData.length;
        worksheet['!merges'] = [{
            s: {
                c: 0,
                r: 0
            },
            e: {
                c: mergeRange.e.c,
                r: headerData.length - 1
            },
        }];

        // 设置列宽
        worksheet['!cols'] = [{
            wch: 10
        }, {
            wch: 10
        }, {
            wch: 10
        }, {
            wch: 20
        }, {
            wch: 20
        }, {
            wch: 20
        }, {
            wch: 20
        }, {
            wch: 20
        }, ];

        XLSX.utils.book_append_sheet(wb, worksheet, SheetName);
        XLSX.writeFile(wb, fileName);
    }
units.IdCard = (IdCard, type) => { //当type=1时获取出生日期,type=2时获取性别,type=3时获取年龄

    if (type === 1) {
        //获取出生日期
        let birthday = IdCard.substring(6, 10) + "-" + IdCard.substring(10, 12) + "-" + IdCard.substring(12, 14)
        return birthday
    }
    if (type === 2) {
        //获取性别
        if (parseInt(IdCard.substr(16, 1)) % 2 === 1) {
            return "男"
        } else {
            return "女"
        }
    }
    if (type === 3) {
        //获取年龄
        var ageDate = new Date()
        var month = ageDate.getMonth() + 1
        var day = ageDate.getDate()
        var age = ageDate.getFullYear() - IdCard.substring(6, 10) - 1
        if (IdCard.substring(10, 12) < month || IdCard.substring(10, 12) === month && IdCard.substring(12, 14) <= day) {
            age++
        }
        if (age <= 0) {
            age = 1
        }
        return age
    }
}

units.userId = () => {
    return base64.decode(cookie.get('userId'))
}; //获取登录时的userid};
units.ToPdfByOneFile = function (dom, pdfNmae) { //html 转pdf
    // 特别重要：当页面超过一页，出现滚动条，滚动的部分生成的图片为空白
    window.pageYoffset = 0
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    let url = html2canvas(document.querySelector(dom), {
        dpi: '600',
        scale: '2',
    }).then(function (canvas) {
        let pageWidth = canvas.width;
        let pageHeight = canvas.height;
        var pageData = canvas.toDataURL('image/jpeg', 1.0);
        // Create a new PDF with the same dimensions as the image.
        const pdf = new JsPDF({
            orientation: pageHeight > pageWidth ? "portrait" : "landscape",
            unit: "px",
            format: [pageHeight, pageWidth]
        });
        // Add the image to the PDF with dimensions equal to the internal dimensions of the page.
        pdf.addImage(pageData, 0, 0, pageWidth, pageHeight);
        // console.log(pageData);
        //下载图片模块
        //   const a = document.createElement('a'); //创建一个a标签
        //   a.download = pdfNmae+".jpeg"; //下载文件的名字
        //   a.href =pageData;
        //   a.dispatchEvent(new MouseEvent('click')); //自定义事件触发，模拟单击事件；
        //   URL.revokeObjectURL(a.href);
        //下载图片模块
        // Save the PDF with the filename specified here:
        pdf.save(pdfNmae + ".pdf");
    })
};
units.DownloadFiles = (FileName, href) => {
    const a = document.createElement('a'); //创建一个a标签
    a.download = FileName; //下载文件的名字
    a.href = href;
    a.dispatchEvent(new MouseEvent('click')); //自定义事件触发，模拟单击事件；
    URL.revokeObjectURL(a.href);
}
units.ToPdfByOneFile1 = function (dom, pdfName) { //确保不失真
    window.pageYoffset = 0
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0

    let url = html2canvas(document.querySelector(dom), {
        dpi: 600, // 或 600
        scale: window.devicePixelRatio // 设置为设备像素比
    }).then(function (canvas) {
        let pageWidth = canvas.width;
        let pageHeight = canvas.height;
        var pageData = canvas.toDataURL('image/jpeg', 1.0);

        // Create a new PDF with the same dimensions as the image.
        const pdf = new JsPDF({
            orientation: pageHeight > pageWidth ? "portrait" : "landscape",
            unit: "px",
            format: [pageHeight, pageWidth]
        });

        // Add the image to the PDF with dimensions equal to the internal dimensions of the page.
        pdf.addImage(pageData, 0, 0, pageWidth, pageHeight);

        // Save the PDF with the filename specified here:
        pdf.save(pdfName + ".pdf");
    });
};
units.convertImgToBase64 = (url, callback, outputFormat) => {
    let canvas = document.createElement('CANVAS');
    let ctx = canvas.getContext('2d');
    let img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
        canvas.height = this.height;
        canvas.width = this.width;
        ctx.drawImage(this, 0, 0);
        let dataURL = canvas.toDataURL(outputFormat || 'image/png');
        callback(dataURL);
        canvas = null;
    };
    img.src = url;
}
// 将 Base64 图片格式的字符串转换为 URL 安全的 Base64 编码
units.convertToUrlSafeBase64 = (base64String) => {
    // 将 Base64 字符串中的 "+" 替换为 "-"
    let urlSafeBase64 = base64String.replace(/\+/g, '-');
    // 将 Base64 字符串中的 "/" 替换为 "_"
    urlSafeBase64 = urlSafeBase64.replace(/\//g, '_');
    // 移除 Base64 字符串中的 "="
    urlSafeBase64 = urlSafeBase64.replace(/=/g, '');

    return urlSafeBase64;
}
//全屏加载动画
let loading = null;
units.Loading = (LodingMsg) => {
    loading = ElementUI.Loading.service({
        fullscreen: true,
        text: LodingMsg,
        background: 'rgba(0, 0, 0, 0.7)',
        spinner: 'el-icon-loading',
        lock: true,
    });
}
units.LoadingClose = () => {
    loading.close();
}
units.getLocationUrl = (url) => {
    var string = axios.defaults.baseURL;
    var pattern = /^http:\/\/[^/]+(:\d+)?/;
    var match = string.match(pattern);
    return url.replace(/^http:\/\/[^/]+(:\d+)?/, match[0]);
}
units.checkFileType = (file) => {
    const regx = /\.(gif|GIF|jpg|JPG|PNG|png|xlsx|xls|docx|doc|ppt|PPT|pdf|PDF|ZIP|zip|RAR|rar)$/;
    //检测文件类型
    if (!regx.test(file.name)) {
        ElementUI.Notification({
            title: '警告',
            message: '检测到上传文件类型异常,请重新上传!',
            type: 'warning'
        });
        return true;
    }
    return false;
}
units.checkFileSize = (file) => {
    if (file.size > 1024 * 1024 * 128) {
        ElementUI.Notification({
            title: '警告',
            message: '检测上传文件超过128M,目前最大单次上传文件最大为128M!',
            type: 'warning'
        });
        return true;
    }
    return false;
}
units.getImgBase64Url = (file) => {
    return new Promise((resolve, reject) => {
        // 使用 FileReader 读取文件并进行 Base64 编码
        const reader = new FileReader();
        reader.onload = function () {
            const base64Data = reader.result.split(',')[1]; // 获取 Base64 编码后的数据部分

            // 使用 encodeURIComponent 进行 URL 编码
            const urlEncodedData = encodeURIComponent(base64Data);

            // 现在 urlEncodedData 包含了 URL 编码后的 Base64 数据
            resolve(urlEncodedData);

            // 这里你可以将 urlEncodedData 发送到服务器或者进行其他操作
        };

        reader.onerror = function () {
            reject(new Error('读取文件出错'));
        };

        reader.readAsDataURL(file); // 读取文件并触发 onload 事件
    });
}
export default units;